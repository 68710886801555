@font-face {
  font-family: "gsans";
  src: url("./fonts/GeneralSans-Regular.otf");
}
@font-face {
  font-family: "gsans-bold";
  src: url("./fonts/GeneralSans-Bold.otf");
}

@font-face {
  font-family: "gsans-ibold";
  src: url("./fonts/GeneralSans-BoldItalic.otf");
}
@font-face {
  font-family: "gsans-light";

  src: url("./fonts/GeneralSans-Light.otf");
}
@font-face {
  font-family: "gsans-medium";
  src: url("./fonts/GeneralSans-Medium.otf");
}
@font-face {
  font-family: "hurl";
  src: url("./fonts/FontsFree-Net-HURLANT-CONDENSED-PERSONAL-USE.ttf");
}

html,
body {
  font-family: "gsans", sans-serif;
  color: #292929;
  position: relative;
  overflow-x: hidden;
}
body {
  position: relative;
}
.font-gsans {
  font-family: "gsans", sans-serif;
}
.font-gsans-light {
  font-family: "gsans-light", sans-serif;
}
.font-gsans-bold {
  font-family: "gsans-bold", sans-serif;
}
.font-gsans-medium {
  font-family: "gsans-medium", sans-serif;
}

.font-hurl {
  font-family: "hurl", "Courier New", Courier, monospace;
}

.font-huri {
  font-family: "Hurricane", cursive;
}

.header {
  background-image: url("./assets/headerBg.png");
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.item:nth-child(even) {
  order: 2;
}
.item:nth-child(odd) {
  order: 1;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  /* opacity: 0.1; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal-backdrop2 {
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  /* opacity: 0.1; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.is-sticky {
  position: fixed;
  top: 0;
  width: 100%;

  z-index: 40;
}
.form-layout {
  background-image: url("../src/assets/pattern.png"),
    url("../src/assets/ellipse1.png"), url("../src/assets/ellipse2.png");
  background-repeat: no-repeat;
  background-size: 45rem, 30rem, 30rem;
  background-position-x: 0rem, 0rem, 5rem;
  background-position-y: 0rem, 100%, 100%;
}

@media (max-width: 600px) {
  .form-layout {
    background-image: url("../src/assets/pattern.png"),
      url("../src/assets/ellipse1.png"), url("../src/assets/ellipse2.png");
    background-repeat: no-repeat;
    background-size: 45rem, 20rem, 20rem;
    background-position-x: 0rem, 5rem, 10rem;
    background-position-y: 0rem, 130%, 130%;
  }
}
